.dashed1 {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite, fill1 3s infinite ease-in-out;
}

.dashed2 {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite, fill2 3s infinite ease-in-out;
}

@keyframes fill1 {
  0%,
  40% {
    fill: none;
    stroke-width: 2;
  }
  50%,
  80% {
    fill: white;
    stroke-width: 0;
  }
  100% {
    fill: none;
    stroke-width: 2;
  }
}

@keyframes fill2 {
  0%,
  60% {
    fill: none;
    stroke-width: 2;
  }
  70%,
  80% {
    fill: white;
    stroke-width: 0;
  }
  100% {
    fill: none;
    stroke-width: 2;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}
