.container {
  position: relative;
  width: 500px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  position: absolute;
  display: flex;
  width: 500px;
  background-image: linear-gradient(to bottom, #e2e0d6 1px, transparent 1px);
  background-size: calc(500px / 12) calc(500px / 12);
  background-repeat: repeat;
  min-height: 500px;
}

.grid {
  background-size: calc(500px / 12) auto;
  width: 100%;
  background-image: linear-gradient(to right, #e2e0d6 1px, transparent 1px);
  display: flex;
  flex-direction: column;
  min-height: 500px;
}

.gradientTop {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradientBottom {
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradientRight {
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.Left {
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.card {
  width: 318px;
  height: 212px;
  display: flex;
  position: relative;
}

.blur {
  width: 300px;
  height: 300px;
  background: linear-gradient(-45deg, #e7ff51, #55f5a3);
  filter: blur(50px);
  border-radius: 50%;
  position: absolute;
  top: 100px;
  left: 100px;
  animation: blur 3s ease-in-out infinite;
}

@keyframes blur {
  0%,
  20% {
    transform: scale(1.5);
  }
  40%,
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.chip {
  position: absolute;
  left: 30px;
  top: 60px;
  animation: physical 3s ease-in-out infinite;
}

.contactless {
  position: absolute;
  left: 80px;
  top: 66px;
  animation: physical 3s ease-in-out infinite;
}

.logo {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.mc {
  animation: logos 6s 3s ease-in-out infinite;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.visa {
  animation: logos 6s ease-in-out infinite;
  position: absolute;
  bottom: 0;
  right: 0;
}

@keyframes physical {
  0%,
  40% {
    opacity: 0;
  }
  50%,
  80% {
    opacity: 1;
  }
  90%,
  100% {
    opacity: 0;
  }
}

@keyframes logos {
  0%,
  20% {
    opacity: 0;
  }
  25%,
  40% {
    opacity: 1;
  }
  45%,
  100% {
    opacity: 0;
  }
}

.half1,
.half2 {
  display: flex;
}

.half2 {
  transform: rotateY(180deg);
}

.block1,
.block2,
.block3,
.block4,
.block5,
.block6,
.block7,
.block8,
.block9,
.block10,
.block11,
.block12 {
  height: 53px;
  width: 53px;
  background: black;
}

.block1 {
  animation: block1 3s ease-in-out infinite, roundTop 3s ease-in-out infinite;
}

.block2 {
  animation: block2 3s ease-in-out infinite, round 3s ease-in-out infinite;
}

.block3 {
  animation: block3 3s ease-in-out infinite, round 3s ease-in-out infinite;
}

.block4 {
  animation: block4 3s ease-in-out infinite, roundBottom 3s ease-in-out infinite;
}

.block5 {
  animation: block5 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block6 {
  animation: block6 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block7 {
  animation: block7 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block8 {
  animation: block8 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block9 {
  animation: block9 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block10 {
  animation: block10 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block11 {
  animation: block11 3s ease-in-out infinite, round 3s ease-in-out infinite;
}
.block12 {
  animation: block12 3s ease-in-out infinite, round 3s ease-in-out infinite;
}

@keyframes round {
  0%,
  20% {
    border-radius: 50%;
    opacity: 0;
  }
  40%,
  80% {
    border-radius: 0;
    opacity: 1;
  }
  100% {
    border-radius: 50%;
    opacity: 0;
  }
}

@keyframes roundTop {
  0%,
  20% {
    border-radius: 50%;
    opacity: 0;
  }
  40%,
  80% {
    border-radius: 0;
    border-top-left-radius: 16px;
    opacity: 1;
  }
  100% {
    border-radius: 50%;
    opacity: 0;
  }
}

@keyframes roundBottom {
  0%,
  20% {
    border-radius: 50%;
    opacity: 0;
  }
  40%,
  80% {
    border-radius: 0;
    border-bottom-left-radius: 16px;
    opacity: 1;
  }
  100% {
    border-radius: 50%;
    opacity: 0;
  }
}

@keyframes block1 {
  0%,
  20% {
    transform: translate(-50px, -50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50px, -50px);
  }
}

@keyframes block2 {
  0%,
  20% {
    transform: translate(-50px, -15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50px, -15px);
  }
}

@keyframes block3 {
  0%,
  20% {
    transform: translate(-50px, 15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50px, 15px);
  }
}

@keyframes block4 {
  0%,
  20% {
    transform: translate(-50px, 50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50px, 50px);
  }
}

@keyframes block5 {
  0%,
  20% {
    transform: translate(-30px, -50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-30px, -50px);
  }
}

@keyframes block6 {
  0%,
  20% {
    transform: translate(-30px, -15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-30px, -15px);
  }
}

@keyframes block7 {
  0%,
  20% {
    transform: translate(-30px, 15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-30px, 15px);
  }
}

@keyframes block8 {
  0%,
  20% {
    transform: translate(-30px, 50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-30px, 50px);
  }
}

@keyframes block9 {
  0%,
  20% {
    transform: translate(-10px, -50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-10px, -50px);
  }
}

@keyframes block10 {
  0%,
  20% {
    transform: translate(-10px, -15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-10px, -15px);
  }
}

@keyframes block11 {
  0%,
  20% {
    transform: translate(-10px, 15px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-10px, 15px);
  }
}

@keyframes block12 {
  0%,
  20% {
    transform: translate(-10px, 50px);
  }
  40%,
  80% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-10px, 50px);
  }
}
