.container {
  display: flex;
  position: relative;
  width: 780px;
}

.collateral {
  height: 552px;
  width: 400px;
  background: white;
  margin-right: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.collateral .every {
  position: absolute;
  top: 20px;
  right: 20px;
}

.collateralTitle {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 10px;
}

.card {
  display: block;
  position: relative;
  background: black;
  width: 337px;
  height: 212px;
  border-radius: 12px;
  color: white;
  font-family: helvetica, sans-serif;
  margin-bottom: 40px;
  animation: background 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
}

.chip {
  position: absolute;
  top: 55px;
  left: 35px;
}

.contactless {
  position: absolute;
  top: 61px;
  left: 90px;
}

.contactless svg path {
  animation: color 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
}

.name {
  position: absolute;
  bottom: 15px;
  left: 20px;
  font-size: 14px;
  animation: color 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
}

.debit {
  position: absolute;
  right: 18px;
  bottom: 60px;
  font-size: 14px;
  animation: first 5s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99);
}

.logo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.brand {
  position: absolute;
  top: 20px;
  right: 18px;
}

.every {
  animation: fleet 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite,
    first 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
  width: 120px;
  height: 30px;
}

.brand2 {
  animation: second 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
  position: absolute;
  top: 25px;
  right: 20px;
  display: flex;
  gap: 5px;
}

.square {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.phone .square {
  width: 25px;
  height: 25px;
}

.collateral .square {
  background: #7299ff;
}

.triangle {
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 30px solid rgba(0, 0, 0, 0.1);
  border-top: 0px solid transparent;
  display: inline-block;
  border-radius: 2px;
}

.phone .triangle {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid rgba(0, 0, 0, 0.1);
}

.collateral .triangle {
  border-bottom: 30px solid #7299ff;
}

.logo svg path,
.brand svg path {
  animation: color 5s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
}

.mc {
  position: absolute;
  bottom: 18px;
  right: 18px;
  animation: second 5s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99);
}

.visa {
  position: absolute;
  bottom: 20px;
  right: 20px;
  animation: first 5s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99);
}

.phone {
  height: 300px;
  width: 337px;
  background: white;
  border: 2px solid #e2e0d6;
  border-radius: 40px 40px 0 0;
  padding: 40px 20px 0 20px;
}

.phone .card {
  width: 297px;
  height: 186px;
}

.phone .brand svg {
  width: 100px;
  height: 25px;
}

.phone .logo svg {
  max-width: 60px;
  max-height: 32px;
}

.phone .dots {
  font-weight: 600;
  letter-spacing: 0.1em;
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: linear-gradient(
    0deg,
    rgba(245, 243, 235, 1) 0%,
    rgba(245, 243, 235, 0) 100%
  );
}

.gradientLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100px;
  background: linear-gradient(
    90deg,
    rgba(245, 243, 235, 1) 0%,
    rgba(245, 243, 235, 0) 100%
  );
}

@keyframes background {
  0%,
  40% {
    background: black;
  }
  50%,
  90% {
    background: #7299ff;
  }
  100% {
    background: black;
  }
}

@keyframes first {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes second {
  0%,
  40% {
    opacity: 0;
  }
  50%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes color {
  0%,
  40% {
    fill: white;
    color: white;
  }
  50%,
  90% {
    fill: black;
    color: black;
  }
  100% {
    fill: white;
    color: white;
  }
}

@media (max-width: 640px) {
  .container {
    width: 337px;
    margin: 0 auto;
  }

  .gradientLeft {
    background: transparent;
  }

  .collateral {
    display: none;
  }
}
