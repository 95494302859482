.title {
  font-size: 100px;
  line-height: 1.1em;
}

.wordCarousel {
  display: inline-block;
  overflow: hidden;
  height: 1.1em;
  line-height: 1em;
  margin-bottom: -0.23em;
  padding-left: 0.2em;
}

.wordCarouselInner {
  position: relative;
  animation: carousel 10s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite;
  animation-fill-mode: both;
}

.wordCarousel span {
  display: block;
  margin-bottom: 0.1em;
}

@keyframes carousel {
  0%,
  10% {
    transform: translateY(0);
  }
  20%,
  30% {
    transform: translateY(-1.1em);
  }
  40%,
  50% {
    transform: translateY(-2.2em);
  }
  60%,
  70% {
    transform: translateY(-3.3em);
  }
  80%,
  90% {
    transform: translateY(-4.4em);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 11vw;
  }
  .wordCarousel {
    padding-left: 0;
  }
}
