.container {
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  height: 800px;
}

.morphGreen {
  background: #55f5a3;
  animation: gradient 12s ease-in-out infinite;
  height: 700px;
  width: 700px;
  top: -200px;
  right: 400px;
  position: absolute;
  border-radius: 50%;
  filter: blur(100px);
}

.morphYellow {
  background: #e7ff51;
  animation: gradient 8s ease-in-out infinite;
  height: 700px;
  width: 700px;
  top: -100px;
  right: -100px;
  position: absolute;
  border-radius: 50%;
  filter: blur(100px);
}

@keyframes gradient {
  0% {
    transform: translate(50px, 50px);
  }
  25% {
    transform: translate(-50px, 50px);
  }
  50% {
    transform: translate(-50px, -50px);
  }
  75% {
    transform: translate(50px, -50px);
  }
  100% {
    transform: translate(50px, 50px);
  }
}

@media (max-width: 1024px) {
  .container {
    height: 600px;
  }

  .morphGreen {
    height: 400px;
    width: 400px;
    top: -100px;
    right: 50%;
  }

  .morphYellow {
    height: 400px;
    width: 400px;
    top: -50px;
    right: -100px;
  }
}
