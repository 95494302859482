.filled {
  animation: filled 3s infinite;
}

.dashed {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}

@keyframes filled {
  0% {
    transform: translateX(-18px);
  }
  20%,
  80% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-18px);
  }
}
