.container {
  position: relative;
  height: 200px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.line {
  margin-top: 39px;
}

.line line {
  stroke-dasharray: 2 6;
  animation: dash 5s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -40;
  }
}

.solidline {
  height: 2px;
  width: 100%;
  background: black;
}

.center {
  display: flex;
  position: relative;
}

.highnote,
.business {
  height: 80px;
  width: 80px;
  border: 2px solid black;
  border-radius: 50%;
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlayRight {
  height: 100%;
  width: 70px;
  top: 0;
  right: 0;
  position: absolute;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlayLeft {
  height: 100%;
  width: 70px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.transaction,
.transaction2,
.transaction3,
.transaction4 {
  height: 20px;
  width: 20px;
  background: black;
  position: absolute;
  border-radius: 50%;
  left: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transaction path,
.transaction2 path,
.transaction4 path {
  animation: 10s statusStroke linear infinite;
}

.transaction,
.transaction2,
.transaction4 {
  animation: 10s status linear infinite, 10s transaction linear infinite;
}

.transaction2,
.transaction2 path {
  animation-delay: 2.5s;
}

.transaction3 {
  animation: 10s 5s status2 linear infinite, 10s 5s transaction linear infinite;
}

.transaction3 rect {
  animation: 10s 5s statusFill linear infinite;
}

.transaction4,
.transaction4 path {
  animation-delay: 7.5s;
}

@keyframes transaction {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(400px);
  }
}

@keyframes status {
  0%,
  50% {
    background: black;
  }

  60%,
  100% {
    background: #53ec9d;
  }
}

@keyframes status2 {
  0%,
  50% {
    background: black;
  }

  60%,
  100% {
    background: #f0344d;
  }
}

@keyframes statusStroke {
  0%,
  50% {
    stroke: black;
  }

  60%,
  100% {
    stroke: white;
  }
}

@keyframes statusFill {
  0%,
  50% {
    fill: black;
  }

  60%,
  100% {
    fill: white;
  }
}
