.container {
  position: relative;
  font-size: 14px;
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardWrapper {
  height: 130px;
  position: relative;
  width: 210px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    rgba(85, 245, 163, 1) 0%,
    rgba(225, 255, 37, 0.8) 100%
  );
}

.card {
  height: 130px;
  width: 210px;
  background: #f5f3eb;
  animation: card 4s infinite;
}

.chip {
  position: absolute;
  top: 30px;
  left: 20px;
}

.chip svg {
  width: 28px;
}

.contactless {
  position: absolute;
  top: 36px;
  left: 56px;
}

.contactless svg {
  width: 10px;
}

@keyframes card {
  0%,
  20% {
    transform: translateX(0px);
  }
  40%,
  80% {
    transform: translateX(210px);
  }
  100% {
    transform: translateX(0px);
  }
}

.line {
  margin-left: -12px;
}

.line line {
  stroke-dasharray: 2 6;
  animation: dash 8s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}
