.container {
  position: relative;
  height: 200px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.card {
  width: 33%;
  position: absolute;
  left: 33%;
  border: 2px solid black;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  border-bottom-right-radius: 30px;
}

.bank {
  position: absolute;
  left: calc(66% - 18px);
  top: 50px;
}

.phone {
  position: absolute;
  left: calc(66% - 28px);
  top: 120px;
}

.line {
  position: absolute;
  top: 75px;
  left: 0px;
}

.line2 {
  position: absolute;
  top: 155px;
  left: 0px;
}

.line line,
.line2 line {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}

.overlayTop {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlayLeft {
  height: 100%;
  width: 80px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

.wifi1 {
  animation: wifi 2s infinite;
}

.wifi2 {
  animation: wifi 2s 0.5s infinite;
}

.wifi3 {
  animation: wifi 2s 1s infinite;
}

@keyframes wifi {
  0%,
  20% {
    opacity: 0;
  }
  40%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 640px) {
  .line {
    left: -20px;
  }

  .line2 {
    left: -20px;
  }
}
