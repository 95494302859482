.apDashboard {
  position: relative;
  height: 400px;
  width: 800px;
}

.container {
  position: absolute;
  left: 100px;
  width: 700px;
  height: 400px;
  background: rgba(245, 243, 235, 0.5);
  display: flex;
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.04),
    inset 0 1px 1px 0 hsl(0deg 0% 100% / 10%);
  backdrop-filter: blur(2px);
}

.sidebar {
  width: 120px;
  padding: 20px;
}

.navLogo {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.nav {
  margin-top: 20px;
}

.nav1 {
  margin-bottom: 20px;
}

.navGroup {
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
}

.navGroup .circle {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  display: block;
}

.navGroup .rect {
  width: 50px;
  height: 10px;
  background: white;
  border-radius: 5px;
  display: block;
}

.nav2 .navGroup:first-child .circle,
.nav2 .navGroup:first-child .rect {
  animation: nav2 5s ease-in-out infinite;
}

.nav1 .navGroup:last-child .circle,
.nav1 .navGroup:last-child .rect {
  animation: nav1 5s ease-in-out infinite;
}

.main {
  width: 560px;
  position: absolute;
  left: 140px;
}

.search {
  height: 20px;
  width: 250px;
  background: white;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 10px;
  line-height: 20px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}

.content {
  height: 340px;
  background: white;
  margin-top: 20px;
  border-top-left-radius: 10px;
}

.title {
  font-size: 14px;
  padding: 25px 20px;
  display: flex;
  align-items: center;
}

.title:after {
  content: "";
  animation: title 10s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99);
}

.title span {
  width: 30px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
  animation: 10s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite background;
}

.tabs {
  border-bottom: 1px solid #e2e0d6;
  padding: 0 20px;
  display: flex;
  gap: 10px;
  line-height: 12px;
}

.tab:first-child {
  opacity: 1;
  border-bottom: 1px solid black;
  margin-bottom: -1px;
  line-height: 9px;
}

.tab {
  font-size: 10px;
  padding-bottom: 10px;
  opacity: 0.5;
}

.transactionsTitle {
  font-size: 13px;
  margin: 0 20px;
  padding: 15px 0;
  border-bottom: 1px solid #e2e0d6;
  margin-bottom: 10px;
}

.transaction {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.transactionType,
.transactionAmount,
.transactionStatus,
.merchantInfo {
  width: 50px;
  height: 10px;
  background: #e2e0d6;
  border-radius: 5px;
}

.spendRule {
  width: 200px;
  height: 10px;
  background: #e2e0d6;
  border-radius: 5px;
}

.spendIcon {
  width: 10px;
  height: 10px;
  background: #f0344d;
  border-radius: 50%;
  margin-right: 5px;
}

.merchantExtra {
  width: 80px;
  height: 5px;
  background: #e2e0d6;
  border-radius: 3px;
  margin-top: 5px;
}

.transaction:last-child .spendIcon {
  background: #55f5a3;
}

.transaction:last-child .transactionStatus {
  background: #f0344d;
}

.transactionStatus {
  background: #55f5a3;
}

.transactionIcon {
  width: 20px;
  height: 20px;
  background: #e2e0d6;
  border-radius: 50%;
  margin-right: 5px;
}

.transactionInfo,
.spendName {
  display: flex;
  align-items: center;
}

@keyframes title {
  0%,
  10% {
    content: "Commercial Debit Card Product";
  }
  20%,
  30% {
    content: "Commercial Charge Card Product";
  }
  40%,
  50% {
    content: "Fleet Card Product";
  }
  60%,
  70% {
    content: "Payroll Card Product";
  }
  80%,
  100% {
    content: "Card Product";
  }
}

.card {
  display: block;
  position: absolute;
  left: 0px;
  top: 120px;
  background: black;
  width: 337px;
  height: 212px;
  border-radius: 14px;
  animation: 10s infinite ease-in-out floating,
    10s cubic-bezier(0.54, 0.28, 0.24, 0.99) infinite background;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  color: white;
}

.chip {
  position: absolute;
  top: 55px;
  left: 35px;
}

.contactless {
  position: absolute;
  top: 61px;
  left: 90px;
}

.contactless svg path {
  animation: 10s infinite ease-in-out fill;
}

.vertical {
  position: absolute;
  right: 18px;
  bottom: 70px;
  font-size: 14px;
}

.vertical:before {
  animation: vertical 10s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99);
  content: "";
}

.logo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.logo svg path,
.brand svg path {
  animation: 10s infinite cubic-bezier(0.54, 0.28, 0.24, 0.99) fill;
}

.mc {
  position: absolute;
  bottom: 18px;
  right: 18px;
  animation: 10s infinite ease-in-out mc;
}

.visa {
  opacity: 0;
  position: absolute;
  bottom: 20px;
  right: 20px;
  animation: 10s infinite ease-in-out visa;
}

@keyframes floating {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  25% {
    transform: translate3d(0px, 15px, 0px) rotateZ(-2deg);
  }
  50% {
    transform: translate3d(0px, -5px, 0px);
  }
  75% {
    transform: translate3d(0px, 15px, 0px) rotateZ(2deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes background {
  0%,
  10% {
    background: black;
  }
  20%,
  30% {
    background: #4eec9b;
  }
  40%,
  50% {
    background: #f5f3eb;
  }
  60%,
  70% {
    background: #e1ff25;
  }
  80%,
  90% {
    background: white;
  }
  100% {
    background: black;
  }
}

@keyframes vertical {
  0%,
  10% {
    content: "Debit";
    color: white;
  }
  20%,
  30% {
    content: "";
    color: white;
  }
  40%,
  50% {
    content: "Fleet";
    color: black;
  }
  60%,
  70% {
    content: "Debit";
    color: black;
  }
  80%,
  90% {
    content: "";
    color: white;
  }
  100% {
    content: "Debit";
    color: white;
  }
}

@keyframes fill {
  0%,
  10% {
    fill: white;
    color: white;
  }
  20%,
  30% {
    fill: black;
    color: black;
  }
  40%,
  50% {
    fill: black;
    color: black;
  }
  60%,
  70% {
    fill: black;
    color: black;
  }
  80%,
  90% {
    fill: white;
    color: white;
  }
  100% {
    fill: white;
    color: white;
  }
}

@keyframes mc {
  0%,
  10% {
    opacity: 0;
  }
  20%,
  30% {
    opacity: 0;
  }
  40%,
  50% {
    opacity: 1;
  }
  60%,
  70% {
    opacity: 1;
  }
  80%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes visa {
  0%,
  10% {
    opacity: 1;
  }
  20%,
  30% {
    opacity: 1;
  }
  40%,
  50% {
    opacity: 0;
  }
  60%,
  70% {
    opacity: 0;
  }
  80%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
