.row {
  animation: opacity 4s infinite;
}

.row2 {
  opacity: 0;
  animation: opacity 4s 0.5s infinite;
}

.row3 {
  opacity: 0;
  animation: opacity 4s 1s infinite;
}

.row4 {
  opacity: 0;
  animation: opacity 4s 1.5s infinite;
}

.dashed {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes opacity {
  0%,
  20% {
    opacity: 0;
  }
  40%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 40;
  }
}
