.container {
  display: flex;
  position: relative;
  gap: 20px;
  height: 200px;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
  margin-bottom: 20px;
}

.object {
  position: relative;
}

.person {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid black;
  overflow: hidden;
  position: relative;
}

.person svg {
  position: absolute;
  bottom: 5px;
  left: 11px;
}

.scanner {
  margin-top: -2px;
  border-top: 2px solid black;
  height: 82px;
  background: rgba(255, 255, 255, 0.9);
  animation: scanner1 6s infinite;
}

.container .object:nth-child(2) {
  top: 40px;
}

.container .object:nth-child(2) .scanner {
  animation: scanner2 6s infinite;
}

.container .object:last-child .scanner {
  animation: scanner3 6s infinite;
}

.status {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 24px;
  width: 24px;
  background: white;
  border-radius: 50%;
  animation: status1 6s infinite;
}

.container .object:nth-child(2) .status {
  animation: status2 6s infinite;
}

.container .object:last-child .status {
  animation: status3 6s infinite;
}

@keyframes scanner1 {
  0%,
  10% {
    transform: translateY(0);
  }
  20%,
  30% {
    transform: translateY(82px);
  }
  40%,
  100% {
    transform: translateY(0);
  }
}

@keyframes scanner2 {
  0%,
  30% {
    transform: translateY(0px);
  }
  40%,
  50% {
    transform: translateY(82px);
  }
  60%,
  100% {
    transform: translateY(0px);
  }
}

@keyframes scanner3 {
  0%,
  50% {
    transform: translateY(0);
  }
  60%,
  70% {
    transform: translateY(82px);
  }
  80%,
  100% {
    transform: translateY(0);
  }
}

@keyframes status1 {
  0%,
  40% {
    background: white;
    border: 2px solid black;
  }
  50%,
  100% {
    background: #55f5a3;
    border: 2px solid #55f5a3;
  }
}

@keyframes status2 {
  0%,
  60% {
    background: white;
    border: 2px solid black;
  }
  70%,
  100% {
    background: #f0344d;
    border: 2px solid #f0344d;
  }
}

@keyframes status3 {
  0%,
  80% {
    background: white;
    border: 2px solid black;
  }
  90%,
  100% {
    background: #55f5a3;
    border: 2px solid #55f5a3;
  }
}

@media (max-width: 640px) {
  .scanner {
    background: transparent;
  }
}
