.container {
  overflow: hidden;
  position: relative;
  height: 110px;
}

.scroll {
  display: flex;
  align-items: center;
  justify-content: space-around;
  animation: animLeft 100s linear infinite;
  white-space: nowrap;
  font-size: 100px;
  line-height: 100px;
}

.scroll span {
  display: inline-block;
  margin: 0 10px;
}

@keyframes animLeft {
  0% {
    transform: translate(200%, 0%);
  }
  100% {
    transform: translate(-200%, 0%);
  }
}

@media (max-width: 640px) {
  .container {
    height: 50px;
  }
  .scroll {
    font-size: 40px;
    line-height: 40px;
  }
}
