.container {
  position: relative;
  height: 200px;
  font-size: 14px;
  width: 100%;
  padding: 0 10px;
  text-align: left;
}

.ledgers {
  display: grid;
  width: 100%;
  height: 200px;
  padding: 20px 20px 0 20px;
  border-radius: 0 0 20px 20px;
}

.box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.box path {
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}

.ledger {
  display: flex;
  justify-content: space-between;
}

.ledger:first-child .amount:before {
  animation: cash 6s ease-in-out infinite;
  content: "";
}

.ledger:nth-child(2) .amount:before {
  animation: funds 6s ease-in-out infinite;
  content: "";
}

.ledger:nth-child(3) .amount:before {
  animation: refund 6s ease-in-out infinite;
  content: "";
}

.ledger:last-child .amount:before {
  animation: auths 6s ease-in-out infinite;
  content: "";
}

.overlayTop {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 10%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes cash {
  0%,
  20% {
    content: "$20,000 USD";
  }
  30%,
  50% {
    content: "$19,000 USD";
  }
  60%,
  80% {
    content: "$25,000 USD";
  }
  90%,
  100% {
    content: "$20,000 USD";
  }
}

@keyframes funds {
  0%,
  20% {
    content: "$150 USD";
  }
  30%,
  50% {
    content: "$0 USD";
  }
  60%,
  80% {
    content: "$325 USD";
  }
  90%,
  100% {
    content: "$150 USD";
  }
}

@keyframes refund {
  0%,
  20% {
    content: "$0 USD";
  }
  30%,
  50% {
    content: "$200 USD";
  }
  60%,
  80% {
    content: "$520 USD";
  }
  90%,
  100% {
    content: "$0 USD";
  }
}

@keyframes auths {
  0%,
  20% {
    content: "$1000 USD";
  }
  30%,
  50% {
    content: "$300 USD";
  }
  60%,
  80% {
    content: "$670 USD";
  }
  90%,
  100% {
    content: "$1000 USD";
  }
}
