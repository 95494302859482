.container {
  position: relative;
  height: 200px;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.spendRules {
  display: grid;
  gap: 15px;
  animation: marquee 10s ease-in-out infinite;
}

.spendRule {
  background: rgba(255, 255, 255, 0.4);
  border: 2px solid black;
  padding: 10px 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.spendRule:nth-child(2) .circle {
  animation: switch1 10s ease-in-out infinite;
}

.spendRule:nth-child(3) .circle {
  animation: switch2 10s ease-in-out infinite;
}

.spendRule:nth-child(4) .circle {
  animation: switch3 10s ease-in-out infinite;
}

.spendRule:nth-child(2) .switch {
  animation: switch1Background 10s ease-in-out infinite;
}

.spendRule:nth-child(3) .switch {
  animation: switch2Background 10s ease-in-out infinite;
}

.spendRule:nth-child(4) .switch {
  animation: switch3Background 10s ease-in-out infinite;
}

.spendRule:last-child .switch {
  background: #f5f3eb;
}

.spendRule:first-child .circle {
  margin-left: 22px;
}

.spendRule .switch {
  height: 20px;
  width: 40px;
  background: #55f5a3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.switch .circle {
  height: 16px;
  width: 16px;
  background: white;
  border-radius: 50%;
  margin-left: 2px;
}

.overlayTop {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.overlayBottom {
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

@keyframes marquee {
  0% {
    transform: translateY(5%);
  }
  80% {
    transform: translateY(-55%);
  }
  100% {
    transform: translateY(5%);
  }
}

@keyframes switch1 {
  0%,
  10% {
    transform: translateX(0);
  }
  20%,
  80% {
    transform: translateX(20px);
  }
  90%,
  100% {
    transform: translateX(0);
  }
}

@keyframes switch2 {
  0%,
  30% {
    transform: translateX(0);
  }
  40%,
  80% {
    transform: translateX(20px);
  }
  90%,
  100% {
    transform: translateX(0);
  }
}

@keyframes switch3 {
  0%,
  50% {
    transform: translateX(0);
  }
  60%,
  80% {
    transform: translateX(20px);
  }
  90%,
  100% {
    transform: translateX(0);
  }
}

@keyframes switch1Background {
  0%,
  10% {
    background: #f5f3eb;
  }
  20%,
  80% {
    background: #55f5a3;
  }
  90%,
  100% {
    background: #f5f3eb;
  }
}

@keyframes switch2Background {
  0%,
  30% {
    background: #f5f3eb;
  }
  40%,
  80% {
    background: #55f5a3;
  }
  90%,
  100% {
    background: #f5f3eb;
  }
}

@keyframes switch3Background {
  0%,
  50% {
    background: #f5f3eb;
  }
  60%,
  80% {
    background: #55f5a3;
  }
  90%,
  100% {
    background: #f5f3eb;
  }
}
