.container {
  display: flex;
  align-content: center;
  height: 200px;
  position: relative;
}

.network {
  width: 50%;
  position: relative;
}

.logos {
  height: 40px;
  width: 80px;
  margin: 80px auto 0;
}

.visa,
.mc {
  position: absolute;
}

.stroke {
  top: 99px;
  right: 0;
  position: absolute;
  stroke-dasharray: 2 6;
  animation: dash 10s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -80;
  }
}

.data {
  width: 50%;
  border-left: 2px solid black;
  padding: 0 20px;
  overflow: hidden;
  height: 200px;
  position: relative;
  text-align: left;
  font-size: 14px;
}

.gradientTop {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradientBottom {
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradientRight {
  width: 80px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.dataScroll {
  animation: marquee 20s ease-in-out infinite;
  width: 200px;
}

.dataScroll div {
  padding-bottom: 10px;
}

.visa {
  animation: visa 10s ease-in-out infinite;
}

.mc {
  animation: mc 10s ease-in-out infinite;
}

@keyframes marquee {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes visa {
  0%,
  40% {
    opacity: 1;
  }
  50%,
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mc {
  0%,
  40% {
    opacity: 0;
  }
  50%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
